import useSWR from 'swr'
import ApiError from 'lib/network/ApiError'
import AppConfig from 'types/AppConfig'

const useAppConfig = () => {
  const { data, ...result } = useSWR<AppConfig, ApiError>('/api/v2/app_configs', {
    revalidateOnFocus: false,
    isPaused: () => false,
  })
  return { ...result, data }
}

export default useAppConfig
