/* eslint-disable import/prefer-default-export */
import { MenuCategory } from 'types/Menu'

export const TOP_LEVEL_MENU = {
  women: 'Women',
  men: 'Men',
}

export const ACTIVE_GENDER_MENU = 'activeGenderMenu'
export const ACTIVE_SUBMENU_TITLE = 'activeSubMenuTitle'

export const HIGHLIGHTED_PRODUCTS_TITLE_HEIGHT = '24px'
export const HIGHLIGHTED_PRODUCTS_HEIGHT = '300px'

export const NEWEST_ARRIVAL_EXPERIMENT = 'newest_arrival_experiment_v2'
export const NEWEST_ARRIVAL_EXPERIMENT_WOMENS_URL = '/collections/womens-newest-arrivals-2'
export const NEWEST_ARRIVAL_EXPERIMENT_MENS_URL = '/collections/mens-newest-arrivals-2'

export const DEFAULT_WOMEN_MENU = {
  text: 'Women',
  permalink: '',
  placement: '',
  platform: 'desktop-mobile',
  categories: [
    {
      permalink: '/collections/womens-newest-arrivals',
      text: 'Newest Arrivals',
    } as MenuCategory,
  ],
}
export const DEFAULT_MEN_MENU = {
  text: 'Men',
  permalink: '',
  placement: '',
  platform: 'desktop-mobile',
  categories: [
    {
      permalink: '/collections/mens-newest-arrivals',
      text: 'Newest Arrivals',
    } as MenuCategory,
  ],
}
