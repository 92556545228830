/* eslint-disable import/prefer-default-export */

export function getPageFromUrl(href, search = '') {
  let target = href

  if (href.includes('mix-and-match/')) {
    // No longer supported
    target = '/'
  } else if (href.includes('products/')) {
    target = '/products/[permalink]'
  } else if (href.includes('collections/')) {
    target = '/collections/[permalink]'

    if (href.includes('style/') && href.includes('color/')) {
      target = '/collections/[permalink]/style/[style]/color/[color]'
    } else if (href.includes('style/')) {
      target = '/collections/[permalink]/style/[style]'
    } else if (href.includes('color/')) {
      target = '/collections/[permalink]/color/[color]'
    }
  } else if (href.includes('checkout/preview')) {
    target = '/checkout'
  }

  return target + search
}

export function wantsDotcom(href) {
  const DOTCOM_PATHS = [
    /^\/supply-chain$/,
    /^\/builder-test-editor$/,
    /^\/careers$/,
    /^\/factories(?:\/|$)/,
    /^\/help$/,
    /^\/international$/,
    /^\/reset(?:\/|$)/,
    /^\/reset_pin$/,
    /^\/reviews\/new\/*/,
    /^\/sitemap\.xml$/,
    /^\/sitemap(?:\/|$)/,
    /^\/sms-signup\/.*/,
    /^\/upc-finder$/,
  ]

  return DOTCOM_PATHS.some(re => re.test(href))
}

export function isInternal({ href }) {
  const { protocol, hostname } = document.location
  const targetUrl = new URL(href, document.location)
  return (
    targetUrl.protocol === protocol &&
    targetUrl.hostname === hostname &&
    !wantsDotcom(targetUrl.pathname)
  )
}
