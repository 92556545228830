import { useState, useEffect } from 'react'
import { builder } from '@builder.io/react'
import useSWR from 'swr'

import { getAll } from 'lib/network/fetchers/builder'
import useAppConfig from './useAppConfig'
import useGeolocation from './useGeolocation'
import useSession from './useSession'

const useMenus = () => {
  const [data, setData] = useState([])
  const { data: session } = useSession()
  const { data: appConfig } = useAppConfig()
  const { data: geolocation } = useGeolocation()

  const countryCode = geolocation?.countryCode
  const domesticCustomer = countryCode ? countryCode === 'US' : true

  const params = {
    apiKey: appConfig?.builderApiKey,
    userAttributes: { countryCode, domesticCustomer },
  }

  const {
    data: response,
    isValidating,
    error,
    ...result
  } = useSWR<any, Error>(
    () => (!appConfig || !session ? null : 'menu-v-3'),
    path => getAll(path, params),
  )

  useEffect(() => {
    if (response) {
      setData(
        response.map(bd => ({
          ...bd.data,
          contentId: bd.id,
          testVariationId: bd.testVariationId,
        })),
      )
    }

    response?.forEach(br => builder.trackImpression(br.id, (br as any).testVariationId))
  }, [response])

  return { data, isValidating, error, result }
}

export default useMenus
