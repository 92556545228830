import builder from '@builder.io/react'
import { Fetcher } from 'swr'
import { handleResponse } from '../utils'

type MenuResponse = {
  results: any[]
}

export const builderUrl = (path: string, params = {}): string => {
  const base = `https://cdn.builder.io/api/v3${path}`
  const queryString = new URLSearchParams(params).toString()

  if (queryString) {
    return `${base}?${queryString}`
  }

  return base
}

export const get = async <T>(path: string, params = {}) => {
  const response = await fetch(builderUrl(path, params), { mode: 'cors' })

  return handleResponse<T>(response)
}

// builder.getAll method is capable to fetch all variations of
// a given content, allowing folks to run AB tests with Builder tooling
export const getAll = async (content: string, params = {}) => {
  // it's required to change the response type to 'unknown' before
  // making it a Fetcher
  const response = (await builder.getAll(content, params)) as unknown

  return response as Fetcher<MenuResponse>
}
