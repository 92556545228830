import styles from './styles.module.scss'

const SkipLink = () => {
  return (
    <a href="#everlane-main" className={styles['skip-link']}>
      Skip to Main Content
    </a>
  )
}

export default SkipLink
