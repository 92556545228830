import { createContext, Dispatch, SetStateAction } from 'react'

import Collection from 'types/Collection'
import GiftcardReservation from 'types/GiftcardReservation'
import Product from 'types/Product'
import Menu, { MenuGroup } from 'types/Menu'

type GiftcardModalEditingData = {
  price: string | number
  presetValues: GiftcardReservation
  updateGiftcardLineItem: (giftcardData: GiftcardReservation) => void
}

interface NavigationContext {
  collection: Collection
  product: Product
  isAddToCartModalOpen: boolean
  giftcardModalEditingData?: GiftcardModalEditingData
  isGiftcardModalOpen: boolean
  isNavigationMenuOpen: boolean
  isBannerSticky: boolean
  isRegionDropdownOpen: boolean
  openAddToCartModal: () => void
  closeAddToCartModal: () => void
  setGiftcardModalEditingData?: Dispatch<SetStateAction<GiftcardModalEditingData>>
  setIsGiftcardModalOpen: Dispatch<SetStateAction<boolean>>
  setIsNavigationMenuOpen: Dispatch<SetStateAction<boolean>>
  setIsBannerSticky: Dispatch<SetStateAction<boolean>>
  setIsRegionDropdownOpen: Dispatch<SetStateAction<boolean>>
  menus: Menu[]
  genderMenuTitle: string
  setGenderMenuTitle: Dispatch<SetStateAction<string>>
  genderMenu: Menu
  setGenderMenu: Dispatch<SetStateAction<Menu>>
  womenMenu: Menu
  setWomenMenu: Dispatch<SetStateAction<Menu>>
  menMenu: Menu
  setMenMenu: Dispatch<SetStateAction<Menu>>
  nonGenderMenu: Menu[]
  setNonGenderMenu: Dispatch<SetStateAction<Menu[]>>
  subMenu: MenuGroup
  setSubMenu: Dispatch<SetStateAction<MenuGroup>>
  subMenuTitle: string
  setSubMenuTitle: Dispatch<SetStateAction<string>>
  showTopLevelMenu: boolean
  setShowTopLevelMenu: Dispatch<SetStateAction<boolean>>
  openSubMenu: (itemTitle: string) => void
  closeSubMenu: () => void
}

const NavigationContext = createContext<Partial<NavigationContext>>({})

export default NavigationContext
