import useSWR from 'swr'
import useSession from 'lib/network/hooks/useSession'
import { get } from 'lib/network/fetchers/everlane'
import ApiError from 'lib/network/ApiError'
import Geolocation from 'types/Geolocation'

const urlParams = new URLSearchParams(global?.window?.location.search)
const localeOverride = urlParams.get('locale')

const useGeolocation = () => {
  const { data: session } = useSession()

  const { data, ...result } = useSWR<Geolocation, ApiError>(
    session ? '/api/v2/visitors/geolocate' : null,
    path => get(path, localeOverride ? { localeOverride } : {}),
    { revalidateOnFocus: false },
  )

  return { ...result, data }
}

export default useGeolocation
